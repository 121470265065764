<template>
  <div class="mx-3 mx-md-6 mb-6">
    <v-row class="mt-6">
      <v-col cols="12" md="6">
        <div class="headline mb-1">{{ data.foodCourt.name }}</div>
        <!-- <div cltext-body-2n">Created 7 days ago</div> -->
      </v-col>
      <v-col cols="12" md="6" class="d-flex justify-end">
        <v-btn text class="" color="white" dark @click="onBack">
          <v-icon dark left> mdi-arrow-left </v-icon>Back
        </v-btn>
      </v-col>
    </v-row>
    <v-card class="card mt-6 pa-6">
      <v-row>
        <v-col cols="6">
          <div class="text-h6 mb-7">Basic Information</div>
        </v-col>
        <v-col cols="6" class="d-flex flex-row-reverse">
          <v-btn
            @click="onFcEdit"
            text
            color="primary"
            disabled
          >
            <v-icon small left>mdi-pencil</v-icon>
            <span class="text-button">Edit Information</span>
          </v-btn>
        </v-col>
      </v-row>
      <div class="text-body-2 grey--text mb-2">Restaurant Address</div>
      <div class="text-body-1 mb-6">
        {{ data.foodCourt.address }}
      </div>
      <v-row>
        <v-col cols="6">
          <div class="text-body-2 grey--text mb-2">Owner Name</div>
          <div class="text-body-1 mb-0">{{ data.foodCourt.user.name }}</div>
        </v-col>
        <v-col cols="6">
          <div class="text-body-2 grey--text mb-2">Package Name</div>
          <div class="text-body-1 mb-0">
            {{ data.foodCourt.subscription.packages[0].name }}
          </div>
        </v-col>
      </v-row>
    </v-card>

    <v-card class="card mt-6 pa-6">
      <div class="text-h6 mb-7">Security Information</div>
      <v-row>
        <v-col cols="6">
          <div class="text-body-2 grey--text mb-2">Contact</div>
          <div class="text-body-1 mb-6">{{ data.foodCourt.user.cell }}</div>
        </v-col>
        <v-col cols="6">
          <div class="text-body-2 grey--text mb-2">Email</div>
          <div class="text-body-1 mb-6">{{ data.foodCourt.user.email }}</div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { eventBus } from "../../main";
export default {
  props: ["data"],
  created() {
    // console.log("getting daat in details", this.data);
  },
  data() {
    return {}
  },
  methods: {
    onBack() {
      eventBus.$emit("backToList")
    },
    onFcEdit() {
      // console.log("foodcourt edit")
      eventBus.$emit("backToAddFc")
    },
  },
};
</script>

